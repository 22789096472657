import React from 'react';
import '../App.scss';

function ThemeButton(props) {
    const { theme, toggleTheme } = props;

    return (
        theme === 'light' ?
            <svg version="1" xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 150 150" class="ThemeButton" onClick={toggleTheme}>
                <path d="M51.9 13.9c-8.7 3.4-16.4 8.5-23.4 15.6C16.3 41.7 10 57.2 10 75c0 18.9 5.6 32.7 18.5 45.5 9.2 9.3 18.2 14.5 30.1 17.5 25 6.4 52.7-3.2 68.3-23.6 4.2-5.7 10.2-18.4 10.8-23.3 1-7.4-2.7-9-9.4-4.2-15.9 11.5-36.3 12.5-52.4 2.6-22.9-14.2-30-44.7-15.4-66.2 6.7-9.9 3-13.9-8.6-9.4zm-5.6 17.2C44 37.8 43 48.8 44 56.6c3 23.5 20.2 42.5 43.8 48 8.3 2 21 1.4 30-1.4 3.4-1.1 6.2-1.8 6.2-1.5 0 .3-1.5 2.6-3.4 5.2-11.3 15.2-27.2 23.3-46 23.3-24.4 0-46.2-16.7-53.2-40.7-2.4-8.3-2.2-22 .5-30.5 2.2-7 7.6-16.8 11.9-21.4C37.1 34.1 45.9 27 47 27c.4 0 .1 1.9-.7 4.1z"/>
            </svg> :
            <svg version="1" xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 150 150" class="ThemeButton" onClick={toggleTheme}>
                <path d="M71.2 13.2c-1.6 1.6-1.6 18 0 19.6.7.7 2.4 1.2 3.8 1.2 4.2 0 5-1.7 5-11s-.8-11-5-11c-1.4 0-3.1.5-3.8 1.2zM29.2 30.2c-2.4 2.4-1.2 5.9 3.9 11.3 2.8 3 6 5.7 7 6 3 1 5.8-.4 6.5-3.2.6-2.1-.3-3.5-5.7-8.9-6.3-6.3-9.2-7.7-11.7-5.2zM109.1 35.4c-5.4 5.4-6.3 6.8-5.7 8.9.7 2.8 3.5 4.2 6.5 3.2 1-.3 4.2-3 7-6 5.1-5.4 6.3-8.9 3.9-11.3-2.5-2.5-5.4-1.1-11.7 5.2zM68 44.4c-7.2 1.6-11.2 3.8-16.1 8.9-5.1 5.2-7.4 9.7-8.8 17-1.2 6.6 0 15 2.9 20.7 2.4 4.6 9.4 11.6 14 14 8.7 4.4 21.4 4.4 30.3-.1 4.6-2.4 11.2-9.1 13.7-13.9 4.4-8.7 4.4-21.4-.1-30.3-2.4-4.6-9.1-11.2-13.9-13.7-3-1.5-13.9-4.2-15.9-3.9-.3 0-3.1.6-6.1 1.3zm14.3 10.2C90.7 57.4 98 67.4 98 76c0 8.6-7.3 18.6-15.7 21.4-5.9 2-8.7 2-14.6 0C59.3 94.6 52 84.6 52 76c0-8.4 7.3-18.5 15.3-21.4 5.5-1.9 9.4-1.9 15 0zM12.2 72.2c-1.5 1.5-1.5 6.1 0 7.6.7.7 4.4 1.2 9.8 1.2 9.3 0 11-.8 11-5s-1.7-5-11-5c-5.4 0-9.1.5-9.8 1.2zM118.2 72.2c-1.5 1.5-1.5 6.1 0 7.6.7.7 4.4 1.2 9.8 1.2 9.3 0 11-.8 11-5s-1.7-5-11-5c-5.4 0-9.1.5-9.8 1.2zM33.8 109.8c-5.9 6-7.2 9.4-4.6 12 2.4 2.4 5.4 1.2 11.6-5.1 6.4-6.5 7.3-8.5 4.6-11.1-2.8-2.8-5.5-1.8-11.6 4.2zM104.6 105.6c-2.8 2.8-1.8 5.5 4.2 11.6 6.1 6 8.8 7 11.6 4.2 2.8-2.8 1.8-5.5-4.2-11.6-6.1-6-8.8-7-11.6-4.2zM71.2 119.2c-1.6 1.6-1.6 18 0 19.6 1.5 1.5 6.1 1.5 7.6 0 1.6-1.6 1.6-18 0-19.6-.7-.7-2.4-1.2-3.8-1.2s-3.1.5-3.8 1.2z"/>
            </svg>
    );
}

export default ThemeButton;